import { getAction, postAction } from '@/utils/http.js'//引入封装的get post方法

//侧边栏
const getAside = (params)=>getAction("/api/official/website/categories/sidebar",params);
//详情文章
const getArticle = (params)=>getAction("/api/official/website/articles/getArticle",params);
//热门产品
const hotProducts = (params)=>getAction("/api/official/website/articles/getHotArticle",params);
//产品选型表
const productTable = (params)=>getAction("/api/official/website/productTables/product",params);
//其他芯片
const otherChip = (params)=>getAction("/api/official/website/productTables/productOtherChip",params);
//二级分类（页脚下动态菜单）
const secondMenu = (params)=>getAction("/api/official/website/categories/secondMenu",params);

const getFile = (params)=>getAction("/api/official/website/files/getFilesById",params);
export {
    getAside,
    getArticle,
    hotProducts,
    productTable,
    otherChip,
    secondMenu,
    getFile,
}