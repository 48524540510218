<template>

  <div class="t_container">
    
    <div class="topTitle">
      <!-- <a href="https://www.wch.cn/login" target="_blank" id="userLogin">
        <img
          style="width: 15px; margin-right: 5px"
          src="@/assets/image/user.png"
        />用户
      </a> -->
      <a href="http://www.wch-ic.com/" target="_blank" id="english">
        <img
          style="width: 13px; height: 13px; margin-right: 5px"
          src="@/assets/image/language.png"
        />English
      </a>
      <!-- <ul class="menu">
        <li class="has-submenu">
          <a
            ><img
              style="width: 13px; height: 13px; margin-right: 5px"
              src="@/assets/image/language.png"
            />选择语言</a
          >
          <ul class="submenu">
            <li>
              <a
                id="language"
                href="https://www.wch.cn/"
                target="_parent"
                >简体中文</a
              >
            </li>
            <li>
              <a id="language" href="http://www.wch-ic.com/" target="_blank"
                >English</a
              >
            </li>
          </ul>
        </li>
      </ul> -->
    </div>
    <div class="topBar">
      <a class="navbar-brand" href="https://www.wch.cn">
        <img src="https://www.wch.cn/assets/site/img/logo.png" alt="" />
      </a>
      <div class="topCenter">
        <ul class="nav">
          <li id="product_ul">
            <a href="/products/productsCenter" target="_parent" id="liTitle">产品中心</a>
            <ul id="first">
              <div class="product-div">
                <div class="product-div1">
                  <div class="productTitle_1">接口芯片</div>
                  <div id="interface" class="productTxt_1">
                      <div class="productTxt_">
                          <div class="productTab_1" @click="clickTopBar(topbarData_1.id)">&nbsp;&nbsp;{{topbarData_1.name}}</div>
                          <div style="width:80%;height:2px; background:#ccc; margin-top:5px;margin-bottom: 5px;"></div>
                          <div v-for="(item,i) in topbarData_3.slice(0,3)" :key="i"  @click="clickTopBar(topbarData_1.id,item.name)">
                              <div class="productTab_2"  >&nbsp;&nbsp;{{item.name}}</div>
                          </div>
                          <!-- <div v-if="topbarData_3.length > 0">
                            <div style="display: flex;justify-content: space-between;">
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[3].name)" class="productTab_3">&nbsp;&nbsp;{{topbarData_3[3].name?topbarData_3[3].name:''}}</div>
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[4].name)" class="productTab_4">{{topbarData_3[4].name}}&nbsp;</div>
                            </div>
                            <div style="display: flex;justify-content: space-between;">
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[5].name)" class="productTab_3" style="">&nbsp;&nbsp;{{topbarData_3[5].name}}</div>
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[6].name)" class="productTab_4">{{topbarData_3[6].name}}&nbsp;</div>
                            </div>
                            <div @click="clickTopBar(topbarData_1.id,topbarData_3[7].name)">
                                <div class="productTab_2"  >&nbsp;&nbsp;{{topbarData_3[7].name}}</div>
                            </div>
                            <div @click="clickTopBar(topbarData_1.id,topbarData_3[8].name)">
                                <div class="productTab_2"  >&nbsp;&nbsp;{{topbarData_3[8].name}}</div>
                            </div>
                            <div @click="clickTopBar(topbarData_1.id,topbarData_3[9].name)">
                                <div class="productTab_2"  >&nbsp;&nbsp;{{topbarData_3[9].name}}</div>
                            </div>
                          </div> -->
                          <div v-if="topbarData_3.length > 0">
                            <div style="display: flex;justify-content: space-between;">
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[3].name)" class="productTab_3">&nbsp;&nbsp;{{topbarData_3[3].name?topbarData_3[3].name:''}}</div>
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[4].name)" class="productTab_4">{{topbarData_3[4].name}}&nbsp;</div>
                            </div>
                            <div style="display: flex;justify-content: space-between;">
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[5].name)" class="productTab_3">&nbsp;&nbsp;{{topbarData_3[5].name}}</div>
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[6].name)" class="productTab_4">{{topbarData_3[6].name}}&nbsp;</div>
                            </div>
                            <!-- <div style="display: flex;justify-content: space-between;">
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[7].name)" class="productTab_3">&nbsp;&nbsp;{{topbarData_3[7].name}}</div>
                                <div @click="clickTopBar(topbarData_1.id,topbarData_3[8].name)" class="productTab_4">{{topbarData_3[8].name}}&nbsp;</div>
                            </div> -->
                            <div @click="clickTopBar(topbarData_1.id,topbarData_3[7].name)">
                                <div class="productTab_2"  >&nbsp;&nbsp;{{topbarData_3[7].name}}</div>
                            </div>
                            <div @click="clickTopBar(topbarData_1.id,topbarData_3[8].name)">
                                <div class="productTab_2"  >&nbsp;&nbsp;{{topbarData_3[8].name}}</div>
                            </div>
                            <div @click="clickTopBar(topbarData_1.id,topbarData_3[9].name)">
                                <div class="productTab_2"  >&nbsp;&nbsp;{{topbarData_3[9].name}}</div>
                            </div>
                          </div>
                      </div>
                      <div v-for="(item,index) in topbarData_2" :key="index" class="productTxt_">
                          <div class="productTab_1"  @click="clickTopBar(item.id)">&nbsp;&nbsp;{{item.name}}</div>
                          <div style="width:80%;height:2px; background:#ccc; margin-top:5px;margin-bottom: 5px;"></div>
                          <div v-for="(t,i) in item.childCategoriesVOList" :key="i"  @click="clickTopBar(item.id,t.name)">
                              <div class="productTab_2"  >&nbsp;&nbsp;{{t.name}}</div>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="product-div2">
                  <div class="productTitle">MCU/SoC</div>
                  <div id="productMCU" class="productTxt" >
                    <div class="productTab_container" v-for="(item,index) in topbarData2" :key="index">
                      <div   class="productTab" @click="clickTopBar(item.id)">
                          <div>&nbsp;&nbsp;{{item.name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </li>
          <li>
            <a href="https://www.wch.cn/application.html" target="_blank" id="liTitle" >应用方案</a>
          </li>
          <li id="bbsTitle">
            <a href="https://www.wch.cn/bbs" target="_blank" id="liTitle"
              >沁恒社区</a
            >
            <ul id="first">
              <li>
                <a
                  href="https://www.wch.cn/bbs/forums/tech.html"
                  target="_blank"
                  id="liTitle_1"
                  >技术社区</a
                >
              </li>
              <li>
                <a
                  href="https://www.wch.cn/bbs/forums/sale.html"
                  target="_blank"
                  id="liTitle_1"
                  >销售社区</a
                >
              </li>
            </ul>
          </li>
          <li id="support">
            <a
              href="https://www.wch.cn/services/sale_support.html"
              target="_blank"
              id="liTitle"
              >服务支持</a
            >
            <ul id="second">
              <li>
                <a
                  href="https://www.wch.cn/services/request_sample.html"
                  target="_blank"
                  id="liTitle_1"
                  >样品索取</a
                >
              </li>
              <li>
                <a
                  href="https://www.wch.cn/downloads/category/27.html"
                  target="_blank"
                  id="liTitle_1"
                  >资料下载</a
                >
              </li>
              <li>
                <a
                  href="https://www.wch.cn/services/technical_support.html"
                  target="_blank"
                  id="liTitle_1"
                  >技术支持</a
                >
              </li>
              <li>
                <a
                  href="https://www.wch.cn/services/sale_support.html"
                  target="_blank"
                  id="liTitle_1"
                  >销售服务</a
                >
              </li>
              <!-- <li>
                <a
                  href="https://www.wch.cn/engineering_change_notification.html"
                  target="_blank"
                  id="liTitle_1"
                  >工程变更通知</a
                >
              </li> -->
            </ul>
          </li>
          <li id="aboutUs">
            <a
              href="https://www.wch.cn/about_us.html"
              target="_blank"
              id="liTitle"
              >关于沁恒</a
            >
            <ul id="third">
              <li id="third_1">
                <a
                  href="https://www.wch.cn/about_us.html"
                  target="_blank"
                  id="liTitle_1"
                  >关于我们</a
                >
                <ul id="about_us">
                  <li>
                    <a
                      href="https://www.wch.cn/about_us.html"
                      target="_blank"
                      id="liTitle_1"
                      >公司简介</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.wch.cn/news.html"
                      target="_blank"
                      id="liTitle_1"
                      >新闻动态</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.wch.cn/certification.html"
                      target="_blank"
                      id="liTitle_1"
                      >荣誉与认证</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.wch.cn/statement.html"
                      target="_blank"
                      id="liTitle_1"
                      >法律声明</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="https://www.wch.cn/contact_us.html"
                  target="_blank"
                  id="liTitle_1"
                  >联系我们</a
                >
              </li>
              <li id="third_2">
                <a
                  href="https://www.wch.cn/talentconcept.html"
                  target="_blank"
                  
                  id="liTitle_1"
                  >加入我们</a
                >
                <ul id="join_us" style="top: 67%">
                  <li>
                    <a
                      href="https://www.wch.cn/talentconcept.html"
                      target="_blank"
                      id="liTitle_1"
                      >人才理念</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.wch.cn/recruitment/campus.html"
                      target="_blank"
                      id="liTitle_1"
                      >校园招聘</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.wch.cn/recruitment/joinus.html"
                      target="_blank"
                      id="liTitle_1"
                      >社会招聘</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div style="width: 120px;">
        <a href="https://www.wch.cn/search.html" target="_blank" id="search">
          搜索<img style="width: 20px; height: 20px; margin-left: 10px" src="@/assets/image/search.png"/>
        </a>
      </div>
    </div>
    <div class="topBar_1">
      <a class="navbar-brand" href="https://www.wch.cn">
        <img src="https://www.wch.cn/assets/site/img/logo.png" alt="" />
      </a>
      <div>
        <a href="https://www.wch.cn/search.html" target="_blank" id="search">
        <img
          style="width: 20px; height: 20px;"
          src="@/assets/image/search_gray.png"
        />
      </a>
        <img v-if="showMenu" id="menu_show" style="width: 20px; height: 20px;margin-left: 20px;" src="@/assets/image/close.png"
          @click="showTabMenu"
        />
        <img v-else id="menu_hide" style="width: 20px; height: 20px;margin-left: 20px;" src="@/assets/image/menu.png"
          @click="showTabMenu"
        />
      </div>
    </div>
    <div class="body-div" :class="{ active: showMenu }">
      <div style="margin: 0 auto;width: 75%;margin-top: 20px;">
        <el-collapse @change="handleChange" v-model="activeName">
          <el-collapse-item title="产品中心" name="1" class="collapse-div">
            <el-collapse accordion v-model="activeNames">
              <el-collapse-item title="接口芯片" name="1">
                <div v-for="(item,index) in topbarData1" :key="index" style="color: #fff;" class="body-tab" @click="showMenu=false,clickTopBar(item.id)">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;{{item.name}}</div>
                    <!-- <i class="el-icon-arrow-right"></i> -->
                </div>
              </el-collapse-item>
              <el-collapse-item title="MCU" name="2">
                <div v-for="(item,index) in topbarData2" :key="index" style="color: #fff;" class="body-tab" @click="showMenu=false,clickTopBar(item.id)">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;{{item.name}}</div>
                    <!-- <i class="el-icon-arrow-right"></i> -->
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-collapse-item>
        </el-collapse>
        <a href="https://www.wch.cn/application.html" target="_blank">
          应用方案<i class="el-icon-arrow-right"></i>
        </a>
        <a href="https://www.wch.cn/bbs" target="_blank">
          沁恒社区<i class="el-icon-arrow-right"></i>
        </a>
        <a href="https://www.wch.cn/services/sale_support.html" target="_blank">
          服务支持<i class="el-icon-arrow-right"></i>
        </a>
        <a href="https://www.wch.cn/about_us.html" target="_blank">
          关于沁恒<i class="el-icon-arrow-right"></i>
        </a>
      </div>
    </div>
   
 
  </div>
</template>

<script type="text/javascript" src="https://www.wch.cn/assets/common/common.min.js"></script>
<script>
import $ from 'jquery'
import {getAside } from '@/services/product.js'
export default {
  name: 'topBar',
  data() {
    return {
        topbarData_1:[],
        topbarData_2:[],
        topbarData_3:[],
        topbarData1:[],
        topbarData2:[],
        categoryId:"",
        url:{
            asideUrl:'/api/official/website/categories/sidebar'
        },
        windowWidth: window.innerWidth,
        showMenu:false,
        activeName:[],
        activeNames:['1'],
    };
  },
  computed: {

  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth <= 1030) {
        $('.topBar').css("display","none");
        $('.topBar_1').css("display","flex");
      }else{
        $('.topBar').css("display","flex");
        $('.topBar_1').css("display","none");
        this.showMenu = false
      }
    }
  },
  activated(){

  },
  mounted(){
    this.getTopBarData()
    if (this.windowWidth <= 1030) {
      $('.topBar').css("display","none");
      $('.topBar_1').css("display","flex");
      $('.product-div').hide()
    }else{
      $('.topBar').css("display","flex");
      $('.topBar_1').css("display","none");
      $('.product-div').hide()
    }
    $('#bbsTitle').mouseenter(function() {
      $(this).find('#first').slideDown(300);;
    }).mouseleave(function() {
      $(this).find('#first').stop().slideUp(10);
    });
    $('#support').mouseenter(function() {
      $(this).find('#second').slideDown(300);;
    }).mouseleave(function() {
      $(this).find('#second').stop().slideUp(10);
    });
    $('#aboutUs').mouseenter(function() {
      $(this).find('#third').slideDown(300);;
    }).mouseleave(function() {
      $(this).find('#third').stop().slideUp(10);
    });
    $('#third_1').mouseenter(function() {
      $(this).find('#about_us').slideDown(300);;
    }).mouseleave(function() {
      $(this).find('#about_us').stop().slideUp(10);
    });
    $('#third_2').mouseenter(function() {
      $(this).find('#join_us').slideDown(300);;
    }).mouseleave(function() {
      $(this).find('#join_us').stop().slideUp(10);
    });
    /* $('.has-submenu').mouseenter(function() {
      $(this).find('.submenu').slideDown(300);;
    }).mouseleave(function() {
      $(this).find('.submenu').stop().slideUp(10);
    }); */
    $('#product_ul').mouseenter(function() {
      $(this).find('.product-div').show()
      $(this).find('.product-div').stop().animate({ height: '0px' }, 0);
      $(this).find('.product-div').stop().animate({ height: '470px' }, 300);
    }).mouseleave(function() {
      $(this).find('.product-div').stop().animate({ height: '0px' }, 0);
      $(this).find('.product-div').hide()
    });
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    getTopBarData(){
      getAside().then(res => {
         if(res.data.code===0 && res.data.data.length){
            this.topbarData_1 = res.data.data[0].childCategoriesVOList[0]
            this.topbarData_3 = res.data.data[0].childCategoriesVOList[0].childCategoriesVOList
            this.topbarData_2 = res.data.data[0].childCategoriesVOList.slice(-3)
            this.topbarData1 = res.data.data[0].childCategoriesVOList
            this.topbarData2 = res.data.data[1].childCategoriesVOList
          }else{
            this.$message.error(res.data.message);
          }
      });
    },
    clickTopBar(categoryId,tName){

      this.categoryId = categoryId
      this.$emit('topbarCategoryId',this.categoryId)
      $('.product-div').hide()
      if (categoryId == 69) {
        if (tName) {
          this.$router.push({ path:'/products/productsCenter/otherChip', query: {categoryId:categoryId,tName:tName}})
        } else {
          this.$router.push({ path:'/products/productsCenter/otherChip', query: {categoryId:categoryId}})
        }
      } else if (categoryId == 5) {
        this.$router.push({ path:'/products/productsCenter', query: {categoryId:categoryId}})
      }else if (categoryId == 77) {
        this.$router.push({ path:'/products/QingKe.html', query: {categoryId:categoryId}})
      } else {
        if (tName) {
          this.$router.push({ path:'/products/productsCenter/mcuInterface', query: {categoryId:categoryId,tName:tName}})
        } else {
          this.$router.push({ path:'/products/productsCenter/mcuInterface', query: {categoryId:categoryId}})
        }
      }
    },
    showTabMenu(){
      if (this.showMenu) {
        this.activeName = []
        this.activeNames = ['1']
      }
      this.showMenu = !this.showMenu
    },
    handleChange(status) {
      if (status.length > 0) {

      } else {
        this.activeNames = ['1']
      }
    }
  }
};
</script>

<style scoped>
.nav_product{
  list-style: none;
  width: 20%;
}
.t_container {
  width: 100%;
  margin: 0;
  width: 100%;
  position: fixed;
  top: 0;
  font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
  z-index: 99;
  background: #fff;
}
.navbar-brand img {
  height: 30px;
  width: 120px;
}
.topTitle {
  width: 100%;
  height: 25px;
  background-color: #143657;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 78px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}
.topBar_1 {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 78px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}
.topCenter {
  width: 42.1%;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  cursor: default;
  white-space: nowrap;
  color: #143657;
  min-width: 500px;
}
.product-div {
  display: block;
  position: fixed;
  top: 104px;
  left: 0;
  right: 0;
  width: 1200px;
  margin: 0 auto;
  z-index: 99;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  color: #143657;
  background-color: #fff;
}
.product-div1 {
  display: flex;
  padding: 20px 40px 0px 40px;
}
.product-div2 {
  display: flex;
  align-items: center;
  padding: 0px 40px 20px 40px;
}
.product-div3 {
  display: flex;
  align-items: center;
  padding: 5px 40px 20px 40px;
}
.productTitle {
  width: 10%;
  font-weight: 600;
  font-size: 17px;
  text-align: left;
}
.productTitle_1 {
  width: 10%;
  font-weight: 600;
  font-size: 17px;
  height: 38px;
  line-height: 50px;
  text-align: left;
}
.productTxt {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 90%;
  color: #143657;
  font-size: 16px;
  flex-wrap: wrap;
  padding-top: 5px;
}
.productTxt_1 {
  display: flex;
  justify-content: space-between;
  width: 90%;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
}
.productTxt_2 {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.productTab_container{
  width: 25%;
}
 .productTab {
  height: 38px;
  color: #143657;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 80%;
  margin-bottom: 5px;
}
.productTab:hover {
  background-color: #143657;
  color: #fff;
}
.productTxt_{
    width: 25%;
}
.productTab_1 {
  height: 38px;
  font-size: 16px;
  font-weight: 600;
  color: #143657;
  cursor: pointer;
  /* border-bottom: 2px solid #ccc; */
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 80%;
  margin-top: 5px;
}
.productTab_1:hover {
  background-color: #143657;
  color: #fff;
}
.productTab_2 {
  color: #143657;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  text-decoration: none;
  width: 80%;
}
.productTab_3 {
  color: #143657;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  padding-right: 8px;
  text-decoration: none;
  width: 110%;
}
.productTab_4 {
  color: #143657;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  text-decoration: none;
  width: 100%;
}
.productTab_3:hover {
  background-color: #143657;
  color: #fff;
}
.productTab_4:hover {
  background-color: #143657;
  color: #fff;
}
.productTab_2:hover {
  background-color: #143657;
  color: #fff;
}
.menu {
  list-style: none;
  padding: 16px;
  margin-right: 38px;
  font-size: 12px;
  color: #ccc;
}

.menu li {
  float: left;
  position: relative;
}
.menu li a {
  padding: 5px 0;
  color: #ccc;
  cursor: pointer;
  list-style: none;
  text-decoration: none;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submenu {
  display: none;
  position: absolute;
  list-style: none;
  top: 100%;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
#language {
  list-style: none;
  color: #747474;
  font-size: 12px;
  text-decoration: none;
  background-color: #fff;
  border-radius: 5px;
}
#language:hover {
  list-style: none;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  background-color: #143657;
  border-radius: 5px;
}
.nav {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.nav > li {
  float: left;
  position: relative;
}
.nav > li > ul > li > a {
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: center;
  height: 38px;
}
.nav > li > ul > li > ul > li > a {
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: center;
  height: 38px;
}
.nav > li > ul {
  display: none;
  list-style: none;
  position: absolute;
  top: 53px;
  left: -40px;
  padding: 0px 0px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.nav > li > ul > li > ul {
  display: none;
  list-style: none;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 160px;
  padding: 0px 0px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

#liTitle {
  font-size: 20px;
  font-weight: 600;
  color: #143657;
  text-decoration: none;
  padding: 30px 0px;
}
#liTitle_1 {
  list-style: none;
  font-size: 16px;
  color: #143657;
  text-decoration: none;
  background-color: #fff;
}
#liTitle_1:hover {
  list-style: none;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  background-color: #143657;
}
#liTitle_3 {
  font-size: 16px;
  color: #143657;
  text-decoration: none;
  background-color: #fff;
}
#liTitle_3:hover {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  background-color: #143657;
}
#search {
  font-size: 24px;
  font-weight: 500;
  color: #143657;
  text-decoration: none;
}
#userLogin {
  font-size: 12px;
  color: #ccc;
  text-decoration: none;
  display: flex;
  align-items: center;
}
#english{
  font-size: 12px;
  color: #ccc;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 38px;
}
.outer-div {
  width: 20%;
}
.hover-product {
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: #143657;
  text-decoration: none;
  padding: 30px 0;
}
.hover-program {
  width: 20%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}
.body-div{
  width: 100%;
  height: 100%;
  background-color: rgba(20, 54, 87, 0.9);
  opacity: 0.9;
  position: fixed;
  left: 100%;
  top: 88px;
  z-index: 99;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body-div a {
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  position: relative;
  display: flex;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.body-div .el-collapse{
  border-top: 0px;
  width: 100%;
}
.body-div .el-collapse /deep/ .el-collapse-item__header{
  background-color: rgba(20, 54, 87, 0);
  opacity: 1;
  border-bottom: 0px;
  color:#fff;
  font-size: 18px;
}
.body-div .el-collapse /deep/ .el-collapse-item:last-child{
  margin-bottom: 0;
}
.body-div .el-collapse /deep/ .el-collapse-item__header.is-active{
  border-bottom: 1px solid #ccc;
}
.body-div .el-collapse /deep/ .el-collapse-item__wrap{
  border-bottom: 1px solid #ccc;
  background-color: rgba(20, 54, 87, 0);
  opacity: 1;
  color:#fff;
  font-size: 18px;
}
.body-div .el-collapse /deep/ .el-collapse-item__content{
  padding-bottom: 0px
}
.body-div /deep/ .el-collapse{
  border-bottom: 0px;
}
.body-div .body-tab{
  color:#fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 10px;
}
.body-div  .el-collapse /deep/ .el-collapse-item__arrow{
  margin: 0 0px 0 auto;
}
.body-div  .collapse-div  .el-collapse /deep/ .el-collapse-item__wrap{
  border-bottom: 0px;
}
.body-div  .collapse-div  .el-collapse /deep/ .el-collapse-item__header{
  background-color: unset;
  opacity: 1;
  border-bottom: 0px;
  color:#fff;
  font-size: 18px;
  padding: 0 10px;
}
.active{
  left: 0;
}
#product_ul > ul {
  display: block;
}
</style>