import { getAction, postAction } from '@/utils/http.js'//引入封装的get post方法

//热门产品
const hotProducts = (params)=>getAction("/api/official/website/home_page/get_hot_article",params);
//产品动态
const productDybamics = (params)=>getAction("/api/official/website/home_page/get_product_dynamics",params);

export {
   hotProducts,
   productDybamics,
}