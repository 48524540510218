
import Vue from "vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import scroll from 'vue-seamless-scroll'


//组件
import VueMarkdownEditor from '@kangc/v-md-editor'
// import VueMarkdownPriview from '@kangc/v-md-editor/lib/preview'
// import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html'
// 组件样式
import '@kangc/v-md-editor/lib/style/base-editor.css'
// import '@kangc/v-md-editor/lib/style/preview.css'
// import '@kangc/v-md-editor/lib/style/preview-html.css'
// 插件
// import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
// import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index'
// 插件样式
// import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'
// 主题
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import '@kangc/v-md-editor/lib/theme/style/github.css'
// 语言高亮
// import hljs from 'highlight.js/lib/core'
// import json from 'highlight.js/lib/languages/javascript'
// import javascript from 'highlight.js/lib/languages/javascript'
// 高亮的语言
// hljs.registerLanguage('json', json)
// hljs.registerLanguage('javascript', javascript)
// 使用主题
VueMarkdownEditor.use(githubTheme, {
  // Hljs: hljs
})
// 使用插件
// VueMarkdownEditor.use(createEmojiPlugin())
// VueMarkdownEditor.use(createLineNumbertPlugin())
// 注册组件
Vue.use(VueMarkdownEditor)
// Vue.use(VueMarkdownPriview)
// Vue.use(VMdPreviewHtml)



Vue.use(scroll)
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this // 安装全局事件总线
  }
}).$mount("#app");




