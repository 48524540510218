<template>
    <div class="footer" v-loading="loading">
        <div class="container">
            <!-- 页脚菜单 -->
            <el-row :gutter="0" class="footerRow">
                <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6"  class="footerCol" v-for="(item,index) in footerList" :key="index">
                    <div class="colTitle">{{item.name}}</div>
                    <div class="footerLine">
                        <div class="leftLine"></div>
                        <div class="rightLine"></div>
                    </div>
                    <div class="colContent">
                        <div class="footerItem" @click="clickFooterItem(t)" v-for="(t,i) in item.childMenuList" :key="i">{{t.name}}</div>
                    </div>
                </el-col>
            </el-row>
            
            <!-- 底部 -->
            <div class="bottomPart">
                <div class="left">
                    <a class="tip" href="https://www.wch.cn/statement.html" target="_blank">法律声明</a>
                    <span style="margin:0px 5px;">|</span>
                    <a class="tip" href="https://www.wch.cn/contact_us.html" target="_blank">联系我们</a>
                </div>
                <div class="right">
                    <a class="tip"> ©  2002-2025 南京沁恒微电子股份有限公司</a>
                    <a class="tip" href="http://beian.miit.gov.cn/" target="_blank">苏ICP备18001197号</a>
                    <a class="tip" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011402011012">苏公网安备 32011402011012号</a>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import {secondMenu,} from '@/services/product.js'
import store from '@/store'
export default {
  name: "footer",
  components:{
  },
  data() {
    return {
        loading:false,
        footerList:[
            {
                name:'关于我们',
                childMenuList:[
                    {name:'公司简介', url:'https://www.wch.cn/about_us.html'},
                    {name:'新闻动态', url:'https://www.wch.cn/news.html'},
                    {name:'荣誉与认证', url:'https://www.wch.cn/certification.html'},
                    {name:'法律声明', url:'https://www.wch.cn/statement.html'},
                ],
            },
            {
                name:'技术支持',
                childMenuList:[
                    {name:'技术电话', url:'https://www.wch.cn/services/technical_support.html#tech-tel'},
                    {name:'技术邮箱', url:'https://www.wch.cn/services/technical_support.html#tech-mail'},
                    {name:'销售电话', url:'https://www.wch.cn/services/sale_support.html#sales-tel'},
                    {name:'销售邮箱', url:'https://www.wch.cn/services/sale_support.html#sales-mail'},
                ],
            },
            {
                name:'生态系统',
                childMenuList:[
                    {name:'开发工具', url:'https://www.wch.cn/ecosystems/?development-tools#/'},
                    {name:'在线培训', url:'https://www.wch.cn/ecosystems/?online-training#/'},
                    {name:'RISC-V大学计划', url:'https://www.wch.cn/ecosystems/?rv-university#/'},
                ],

            },
        ],
    };
  },
  computed: {
  },
  created() {
  },
  destroyed() {
  },
  watch: {
  },
  mounted() {
    this.getSecondMenu()
  },
  activated(){
  },
  methods:{
    //获取动态菜单
    getSecondMenu(){
        secondMenu().then(res => {
            this.loading = true;
            let arr = []
            if(res.data.code===0 && res.data.data){
                res.data.data.forEach(item=>{
                    if(item.id!=77){
                        if(item.id==69){//其他芯片
                            arr.push({
                                name:item.name,
                                url:'https://www.wch.cn/products/productsCenter/otherChip?categoryId='+item.id
                            })
                        }else{
                            arr.push({
                                name:item.name,
                                url:'https://www.wch.cn/products/productsCenter/mcuInterface?categoryId='+item.id
                            })
                        }
                    }
                })
                this.$nextTick(()=>{
                    let obj = {
                        name:'产品中心',
                        childMenuList:[].concat(arr)
                    }
                    this.footerList.splice(1,0,obj)
                    store.commit('SET_FOOTERLIST', this.footerList);
                })
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //点击底部菜单
    clickFooterItem(t){
       window.open(t.url)
    },
    
  }
};
</script>
<style scoped lang="less">
 .footer{
    width: 100%;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    min-height: 360px;
    background-color: #143657;
    padding: 20px 0px 30px;
    margin-top: 30px;
    .container{
        width: 75%;
        margin: 0 auto;
    }
    .footerRow .footerCol{
        text-align: left;
        padding-left: 2%;
        padding-top: 30px;
    }
    .footerCol .colTitle{
        font-size: 22px;
        color: #ffffff;
    }
    .footerCol .colTitle{
        font-size: 22px;
        color: #ffffff;
    }
    .colContent .footerItem{
        font-size: 14px;
        color: #ffffff;
        line-height: 28px;
        letter-spacing: 2px;
        cursor: pointer;
    }
    .footerLine{
        margin: 10px 0px;
        display: flex;
    }
    .footerLine .leftLine{
        width: 120px;
        height: 1px;
        border-bottom: 1px solid #0988e5;
    }
    .footerLine .rightLine{
        width: 60px;
        height: 1px;
        border-bottom: 1px dashed #ffffff;
        margin-left: 2px;
    }
    .bottomPart{
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        // line-height: 52px;
        margin-top: 40px;
    }
    .bottomPart .tip{
        font-size: 16px;
        color: #ffffff;
        text-decoration: initial;
    }

     @media (max-width: 1200px) {
        .container{
            width: 90%;
        }
    }
    @media (max-width: 991px) {
        .footerRow .footerCol{
            padding-top: 50px;
        }
        .bottomPart{
            display: block;
        }
    }
 }

</style>
<style scoped>
 
</style>