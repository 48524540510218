<template>
  <div class="container">
    <top-bar ref="topBar"></top-bar>
    <!-- banner -->
    <div class="banner">
        <el-carousel :interval="5000" arrow="always" :height="autoHeight">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <img :style="{cursor:item.link?'pointer':'initial'}" @click="clickBanner(item.link)"  style="width:100%;height:auto;" :src="item.imgUrl" >
            </el-carousel-item>
        </el-carousel>
    </div>
    <!-- 内容 -->
    <div class="main">
        <!-- 产品分类-手机端 -->
        <div class="productType_mb" v-if="isMoPhone">
            <div class="module_title">
                <span class="module_title1">产品</span>
                <span>分类</span>
            </div>
            <div class="content" v-loading="loading1">
                <div class="typeModule">
                    <div class="title">{{productTypeData&&productTypeData[0]?productTypeData[0].name:''}}>>></div>
                    <div class="module_content" v-if="productTypeData[0]&&productTypeData[0].childCategoriesVOList">
                        <div class="type" v-for="(item,index) in productTypeData[0].childCategoriesVOList" :key="index">
                            <img :src="item.icon" alt="">
                            <div class="type_text" @click="clickType(item)">{{item.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="typeModule" style="padding-top: 12px; margin-top: 12px; border-top:3px dashed #d7eafa">
                    <div class="title">{{productTypeData&&productTypeData[1]?productTypeData[1].name:''}}>>></div>
                    <div class="module_content" v-if="productTypeData[1]&&productTypeData[1].childCategoriesVOList">
                        <div class="type" v-for="(item,index) in productTypeData[1].childCategoriesVOList" :key="index">
                            <div class="type_text" @click="clickType(item)">{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 产品分类-电脑端 -->
        <div class="productType_pc" v-else>
            <div class="left" >
                <img src="@/assets/image/home/type.png" alt="">
            </div>
            <div class="right" v-loading="loading1">
                <el-row :gutter="0" style="width: 100%" class="row">
                    <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="col1" >
                        <div class="title1">{{productTypeData&&productTypeData[0]?productTypeData[0].name:''}}</div>
                        <div v-if="productTypeData[0]&&productTypeData[0].childCategoriesVOList">
                            <div class="li1" v-for="(item,index) in productTypeData[0].childCategoriesVOList" :key="index">
                                <img :src="item.icon" alt="">
                                <div class="text1" @click="clickType(item)">{{item.name}}</div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16" class="col2" >
                        <div class="title1">{{productTypeData&&productTypeData[1]?productTypeData[1].name:''}}</div>
                        <div class="liContainer"  v-if="productTypeData[1]&&productTypeData[1].childCategoriesVOList">
                            <div class="li2" v-for="(item,index) in productTypeData[1].childCategoriesVOList" :key="index">
                                <div class="text1" @click="clickType(item)">{{item.name}}</div>
                            </div>

                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
            
        <!-- 热门产品 -->
        <div class="hotProduct">
            <div class="module_title">
                <span class="module_title1">热门</span>
                <span>产品</span>
            </div>
            <div class="content" v-loading="loading2" v-show="isMoPhone">
                <swiper :options="swiperOptions2">
                    <swiper-slide v-for="(item,index) in hotProductsData" :key="index">
                        <div class="hotCard" @click="clickHotCard(item)">
                            <div class="card_title">{{item.leftTitle}}</div>
                            <div class="card_chip">{{item.rightTitle}}</div>
                            <div class="card_text">{{item.text}}</div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <div class="content" v-loading="loading2" v-show="!isMoPhone">
                <swiper :options="swiperOptions">
                    <swiper-slide v-for="(item,index) in hotProductsData" :key="index">
                        <div class="hotCard" @click="clickHotCard(item)">
                            <div class="card_title">{{item.leftTitle}}</div>
                            <div class="card_chip">{{item.rightTitle}}</div>
                            <div class="card_text">{{item.text}}</div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>

        <!-- 产品动态 -->
        <div class="productDynamics">
            <div class="module_title">
                <span class="module_title1">产品</span>
                <span>动态</span>
                <span class="more_" @click="clickMore">更多>>></span>
            </div>
            <div class="content" v-loading="loading3">
                <el-row :gutter="0" style="width: 100%" :type="isMoPhone?'':'flex'">
                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                        <img style="height:100%" src="@/assets/image/home/dynamics.png" alt="">
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="right">
                        <div style="height:100%">
                            <div class="dynamic" v-for="(item,index) in productsDynamicsData" :key="index">
                                <div class="text" @click="clickDynamic(item)"><a :title="item.title">{{item.shortTitle}}</a></div>
                                <div class="date">{{item.productDynamicsTime}}</div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
      
    </div>
    <!-- 底部 -->
    <footer-part ref="footerPart"></footer-part>
  </div>
</template>
<script>
import topBar from '@/pages/topbar.vue'
import footerPart from '@/pages/components/footer.vue'
import { hotProducts, productDybamics } from '@/services/home.js'
import { getAside, } from '@/services/product.js'
// 引入Swiper Vue组件
import { SwiperSlide, Swiper } from "vue-awesome-swiper";
// 引入Swiper核心样式
import "swiper/css/swiper.min.css";

export default {
  name: "homePage",
  components:{
    topBar,
    footerPart,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        autoHeight:'',
        
        bannerList:[
          {imgUrl:require('@/assets/image/home/banner1.jpeg'), link:'https://www.wch.cn/products/productsCenter/mcuInterface?categoryId=70'},
          {imgUrl:require('@/assets/image/home/banner2.jpeg'), link:'https://www.wch.cn/products/productsCenter/mcuInterface?categoryId=63'},
          {imgUrl:require('@/assets/image/home/banner3.png'), link:'https://www.wch.cn/products/productsCenter/mcuInterface?categoryId=4'},
          {imgUrl:require('@/assets/image/home/banner4.png'), link:'https://www.wch.cn/about_us.html'},
        ],
        productTypeData:[],
        loading1:false,

        hotProductsData:[ ],
        loading2:false,
        swiperOptions: {
            slidesPerView: 4, // 每屏4个卡片，根据需要调整
            slidesPerGroup: 4, //4个一组
            spaceBetween: 30, // 卡片间距
            loop: false, // 循环轮播
            // 其他选项...
            autoplay: {
              delay: 3000, //3秒切换一次
              disableOnInteraction: false,// 设置用户操作后是否停止自动播放，false 表示不禁用，true 表示禁用（不推荐设置为 true）
            },
            pauseOnMouseEnter: true, //悬停停止切换
            pagination: { // 控制分页器
                el: ".swiper-pagination",
                type: 'bullets',
                clickable: true, // 此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换
                hideOnClick: false, //默认分页器会一直显示。这个选项设置为true时点击Swiper会隐藏/显示分页器。
            }
        },
        swiperOptions2:{
            slidesPerView: 1, // 每屏4个卡片，根据需要调整
            slidesPerGroup: 1, //4个一组
            spaceBetween: 30, // 卡片间距
            loop: false, // 循环轮播
            // 其他选项...
            autoplay: {
              delay: 3000, //3秒切换一次
              disableOnInteraction: false,// 设置用户操作后是否停止自动播放，false 表示不禁用，true 表示禁用（不推荐设置为 true）
            },
            pauseOnMouseEnter: true, //悬停停止切换
            pagination: { // 控制分页器
                el: ".swiper-pagination",
                type: 'bullets',
                clickable: true, // 此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换
                hideOnClick: false, //默认分页器会一直显示。这个选项设置为true时点击Swiper会隐藏/显示分页器。
            }
        },
       
        productsDynamicsData:[ ],
        loading3:false,
        
    };
  },
  computed: {
   
  },
   
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 768) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('mounted--')
    if (this.windowWidth < 768) {
        this.isMoPhone = true
    }else{
        this.isMoPhone = false
    }
    document.title = '首页 - 南京沁恒微电子股份有限公司'
    if(window.innerWidth< 768){
        this.isMoPhone = true
    }else{
        this.isMoPhone = false
    }
    this.getAutoHeight()
    this.getProductType()
    this.getHotProducts()
    this.getProductsDynamicsData()
  
  },
  activated(){
    console.log('activated')
    document.title = '首页 - 南京沁恒微电子股份有限公司'
    if(window.innerWidth< 768){
        this.isMoPhone = true
    }else{
        this.isMoPhone = false
    }
    this.getAutoHeight()
    this.getProductType()
    this.getHotProducts()
    this.getProductsDynamicsData()
  },
  methods:{
    getAutoHeight(){
        let _w = document.documentElement.clientWidth || document.body.clientWidth; 
        this.autoHeight = _w * 800 / 1920 + 'px'; //banner图在1920像素设计稿下是800px高度
    },
    handleResize() {
      this.windowWidth = window.innerWidth
      this.getAutoHeight()
    },
   
    //点击banner
    clickBanner(link){
      if(link){
        window.open(link)
      }
    },
    //获取产品分类
    getProductType(){
        this.productTypeData = []
        this.loading1 = true
        getAside().then(res => {
            if(res.status===200){
                this.productTypeData = res.data.data
            }else{
                this.$message.error(res.data.message);
            }
            this.loading1 = false
        });
    },
    //点击分类
    clickType(item){
        if(item.id==77){
            window.location.href = 'https://www.wch.cn/products/QingKe.html'
        }else if(item.id==69){
            window.location.href = 'https://www.wch.cn/products/productsCenter/otherChip?categoryId=69'
        }else{
            this.$router.push({ path: '/products/productsCenter/mcuInterface', query: {categoryId:item.id}})
        }
        
    },
    //获取热门产品
    getHotProducts(){
        this.hotProductsData = []
        this.loading2 = true
        hotProducts().then(res => {
            if(res.status===200){
                this.hotProductsData = res.data.data
                console.log('hotProductsData',this.hotProductsData)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading2 = false
        });
    },
    //点击卡片
    clickHotCard(t){
        console.log('点击卡片：',t.rightTitle)
        window.open('https://www.wch.cn/products/'+t.rightTitle)
    },
    //获取产品动态
    getProductsDynamicsData(){
        this.productsDynamicsData = []
        this.loading3 = true
        productDybamics().then(res => {
            if(res.status===200){
                this.productsDynamicsData = res.data.data.slice(0,5)
                console.log('productsDynamicsData',this.productsDynamicsData)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading3 = false
        });
    },
    //点击动态
    clickDynamic(t){
        // window.location.href = 'https://www.wch.cn/news/'+t.id+'.html'
        this.$router.push({ path: '/news/'+t.id+'.html',})
    },
    //点击更多动态
    clickMore(){
        window.location.href = 'https://www.wch.cn/news.html'
    },
        
   
   
    
  }
};
</script>
<style scoped lang="less">
.container {
  width: 100%;
  margin: 0 auto;
  margin-top: 98px;
  overflow: auto;
}

.main {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  text-align: left;
  //   产品分类
  .productType_pc{
    display: flex;
    height: 360px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    box-shadow: -2px 1px 10px 1px rgba(159,159,159,.3);
    margin-bottom: 50px;
    margin-top: 70px;
    .left{
        height: 360px;
        width: 360px;
        img{
            height: 360px;
            width: 360px;
        }
    }
    .right{
        flex: 1;
        padding: 20px 100px;
        background-image: url(~@/assets/image/home/rightBg1.png), url(~@/assets/image/home/rightBg1.png);
        background-repeat: no-repeat, no-repeat;
        background-position: left, right;
        text-align: left;
        .row{
            margin-top: 10px;
        }
        .col1{
           border-right: 3px dashed #d7eafa;
           padding: 0px; 
           padding-left:5%;
        }
        .col2{
           padding: 0px; 
           padding-left:5%;
        }
        .title1{
            font-size: 26px;
            font-weight: 700;
            margin-left: 20px;
            margin-bottom: 15px;
        }
        .text1{
            color: #2e92e7;
            font-size: 24px;
            line-height: 2.5;
            font-weight: 500;
            cursor: pointer;
            &:hover{
               text-decoration: underline;
            }
        }
        .li1{
            display: flex;
            align-items: center;
            padding-left: 12%;
            img{
                width: 30px;
                height: 30px;
                margin-right: 3%;
                line-height: 60px;
            }
        }
        .liContainer{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .li2{
                width: 50%;
                box-sizing: border-box;
                padding-left: 12%;
            }
        }
        
    }
  }
  .productType_mb{
    .content{
        width: 100%;
        padding: 15px;
        background: #fff;
        border: 1px solid #dcdfe6;
        box-shadow: -2px 1px 10px 1px rgba(159,159,159,.3);
        .typeModule{
            .title{
                font-size: 18px;
                line-height: 1.5;
                color: #2e92e7;
                font-weight: 600;
            }
            .module_content{
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                margin-top: 10px;
                .type{
                    width: 50%;
                    display: flex;
                    align-items: center;
                    img{
                        width: 20px;
                        height: 20px;
                        margin-right: 3%;
                        line-height: 60px;
                    }
                    .type_text{
                        color: #2e92e7;
                        font-size: 14px;
                        line-height: 2;
                        font-weight: 500;
                        cursor: pointer;
                        &:hover{
                           text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
  }

    //热门产品
    .hotProduct{
        margin-top: 50px;
        .content{
            padding-bottom: 20px;
            .hotCard{
                padding: 40px 20px;
                height: 320px;
                box-sizing: border-box;
                border: 1px solid #dcdfe6;
                box-shadow: -2px 1px 10px 1px rgba(159,159,159,.3);
                background: #fff;
                color: #2e92e7;
                cursor: pointer;
                transition: all 0.3s;
                &:hover{
                    color: #fff;
                    background: #2e92e7;
                    .card_title{
                       color: #fff;
                    }
                    .card_chip{
                       color: #fff;
                    }
                    .card_text{
                       color: #fff;
                    }
                }
                .card_title{
                    color: #2e92e7;
                    font-size: 24px;
                    line-height: 1.5;
                    font-weight: 600;
                }
                .card_chip{
                    color: #2e92e7;
                    font-size: 30px;
                    line-height: 1.5;
                    font-weight: 600;
                }
                .card_text{
                    color: #a1a1a1;
                    font-size: 20px;
                    line-height: 1.5;
                    padding: 15px 0px;
                    word-break: break-all;
                }
            }
            .swiper-pagination{
                position: absolute; /* 或者使用固定定位 */
                bottom: 0; /* 放在最下方 */
                left: 0; /* 根据需要调整 */
                width: 100%; /* 全宽 */
                text-align: center; /* 居中显示 */
            }
        }
    }

  .module_title{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    line-height: 30px;
    .module_title1{
        border-bottom: 3px solid #2e92e7;
    }
    .more_{
        font-size: 16px;
        float: right;
        color: #2e92e7;
        cursor: pointer;
    }
  }
    //   产品动态
    .productDynamics{
        margin-top: 50px;
        .content{
            .left{
                img{
                    width: 100%;
                }
            }
            .right{
                padding: 22px 30px;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid #dcdfe6;
                box-shadow: -2px 1px 10px 1px rgba(159,159,159,.3);
                .dynamic{
                    display: flex;
                    cursor: pointer;
                    line-height: 2.4;
                    font-size: 25px;
                    text-align: left;
                    width: 100%;
                    .text{
                        flex: 1;
                        color: #333;
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &:hover{
                            color: #2e92e7;
                            text-decoration: underline;
                        }
                    }
                    .date{
                        width: 190px;
                        color: #a1a1a1;
                        text-align: left;
                        margin-left: 3%;
                    }
                }
            }
            
          
        }
    }

}




// 屏幕兼容
  @media screen and (max-width: 1700px) {
    .title1{
        font-size: 22px !important;
    }
    .text1{
        font-size: 20px !important;
    }
    .li1{
        padding-left: 10% !important;
    }
    .li2{
        padding-left: 10% !important;
    }
    .hotProduct .content .hotCard{
        padding: 30px 15px !important;
        height: 270px !important;
    }
    .hotCard .card_title{
        font-size: 20px !important;
        line-height: 1.3 !important;
    }
    .hotCard .card_chip{
        font-size: 24px !important;
    }
    .hotCard .card_text{
        font-size: 18px !important;
    }
  }
  @media screen and (max-width: 1500px) {
    .productType_pc .right{
        padding: 40px 70px !important;
    }
    .text1{
        font-size: 18px !important;
    }
    .col1{
       padding-left: 2% !important;
    }
    .col2{
       padding-left: 2% !important;
    }
    .li1{
        padding-left: 6% !important;
    }
    .li2{
        padding-left: 6% !important;
    }
    .hotProduct .content .hotCard{
        padding: 30px 15px !important;
        height: 250px !important;
    }
    .hotCard .card_title{
        font-size: 18px !important;
        line-height: 1.3 !important;
    }
    .hotCard .card_chip{
        font-size: 22px !important;
    }
    .hotCard .card_text{
        font-size: 16px !important;
    }
    .productDynamics .content .right .dynamic{
        font-size: 22px !important;
        line-height: 2.2 !important;
    }
  }
  @media screen and (max-width: 1300px) {
    .productType_pc{
        height: 300px !important;
    }
    .productType_pc .left{
        width: 300px !important;
        height: 300px !important;
    }
    .productType_pc .left img{
        width: 300px !important;
        height: 300px !important;
    }
    .title1{
        margin-left:5px !important;
    }
    .productType_pc .right{
        padding: 30px 40px !important;
    }
    .li1{
        padding-left: 2% !important;
    }
    .li2{
        padding-left: 2% !important;
    }
    .hotCard .card_text{
        padding:10px 0px 0px !important;
    }
    .hotProduct .content .hotCard{
        padding: 15px !important;
    }
    .productDynamics .content .right .dynamic{
        font-size: 20px !important;
        line-height: 2.2 !important;
    }
  }
  @media screen and (max-width: 1100px) {
    .productType_pc{
        height: 240px !important;
    }
    .productType_pc .left{
        width: 240px !important;
        height: 240px !important;
    }
    .productType_pc .left img{
        width: 240px !important;
        height: 240px !important;
    }
    .productType_pc .right{
        padding: 15px !important;
    }
    .productType_pc .right .row{
        margin-top: 0px !important;
    }
    .productType_pc .right .li1 img{
        width: 22px  !important;
        height: 22px  !important;
    }
    .title1{
        font-size: 20px !important;
    }
    .text1{
        font-size: 16px !important;
    }
    .hotCard .card_title{
        font-size: 16px !important;
        line-height: 1.3 !important;
    }
    .hotCard .card_chip{
        font-size: 20px !important;
    }
    .hotCard .card_text{
        font-size: 14px !important;
    }
    .productDynamics .content .right .dynamic{
        font-size: 18px !important;
        line-height: 2 !important;
    }
  }
   @media screen and (max-width: 992px) {
    .main{
        width: 95% !important;
    }
    .productType_pc{
        height: 180px !important;
    }
    .productType_pc .left{
        width: 180px !important;
        height: 180px !important;
    }
    .productType_pc .left img{
        width:180px !important;
        height: 180px !important;
    }
    .title1{
        font-size: 17px !important;
    }
    .text1{
        font-size: 15px !important;
        line-height: 1.5 !important;
    }
    .hotProduct .content .hotCard{
        height: 270px !important;
    }
    .productDynamics .content .right{
        padding: 15px 10px !important;
    }
    .productDynamics .content .right .dynamic{
        font-size: 16px !important;
        line-height: 2 !important;
    }
  }
   @media screen and (max-width: 767px) {
    .hotProduct .content .hotCard{
        height: 220px !important;
        padding: 20px !important;
    }
    .hotProduct .content .card_title{
        font-size: 18px !important;
        line-height: 1.5 !important;
    }
    .hotProduct .content .card_chip{
        font-size: 22px !important;
        line-height: 1.5 !important;
    }
    .hotProduct .content .card_text{
        font-size: 16px !important;
        line-height: 1.5 !important;
        padding: 15px 0px !important;
    }

   }

</style>