import Vue from 'vue'
import axios from 'axios'; // 引入axios


// 环境的切换

if (process.env.NODE_ENV == 'development') {    
    axios.defaults.baseURL = window._CONFIG['domianURL'];//'http://192.168.111.83:8989/open/api/'
} 
// else if (process.env.NODE_ENV == 'testing') {    
//     axios.defaults.baseURL = 'http://192.168.111.125:8080';
// } 
else if (process.env.NODE_ENV == 'production') {    
    axios.defaults.baseURL = window._CONFIG['domianURL'];
}
// 创建 axios 实例
const service = axios.create({
    baseURL: window._CONFIG['domianURL'], //
    // timeout: 90000 // 请求超时时间
  })

//post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    // config => {
    //     /* 每次发送请求之前判断vuex中是否存在token
    //        如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    //        即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    //     */
    //   //  console.log('token====',Vue.ls.get('Access-Token'))//null
    //     const token = Vue.ls.get('Access-Token');
    //     token && (config.headers.Authorization = token);
    //     return config;
    //     },
    // error => {
    //     return Promise.error(error);
  // }
)
// 响应拦截器
axios.interceptors.response.use(
    response => {
      // 如果返回的状态码为0，说明接口请求成功，可以正常拿到数据
      // 否则的话抛出错误
      if (response.status == 200) {
         return Promise.resolve(response);
      } 
      else {
         return Promise.reject(response);
      }
    },
    error => {
       console.log(error)
})
        
//get
export function getAction(url,parameter) {
    return axios({
      url: url,
      method: 'get',
      params: parameter
    })
  }
//postFormAction
export function postFormAction(url,parameter){
    let formData = new FormData()
    Object.keys(parameter).map((key) => {
      formData.append(key, parameter[key]);
    });
    return axios({
      url: url,
      data: formData,
      method:'post' ,
      headers: {
        'Content-Type': 'multipart/form-data',  // 改变格式
      },
    })
  }

//post
export function postAction(url,parameter) {
  return axios({
    url: url,
    method:'post' ,
    data: parameter
  })
}
//下载get
export function getFileAction(url,parameter) {
  return axios({
    url: url,
    params: parameter,
    method:'get' ,
    responseType: 'blob'
  })
}

  
export {
    service as axios
  }