import Vue from "vue";
import Vuex from "vuex";
import footer from './modules/footer'
// import { getSites, getSofts } from "../services/home";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    footer,
  },
  state: {
    // sites: [],
    // softs: [],
  },
  mutations: {
    // getSite(state, payload) {
    //   state.sites = payload;
    // },
    // getSoft(state, payload) {
    //   state.softs = payload;
    // },
  },
  actions: {
    // getSite({ commit }) {
    //   getSites({}).then((res) => {
    //     commit("getSite", res.data.data);
    //   });
    // },
    // getSoft({ commit }) {
    //   getSofts({}).then((res) => {
    //     commit("getSoft", res.data.data);
    //   });
    // },
  },
});



