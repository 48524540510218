<template>
    <div class="container">
        <top-bar ref="topBar" ></top-bar>
        <!-- banner -->
        <div class="banner">
            <img style="width:100%" src="@/assets/image/contactUs/banner.png" alt="">
        </div>
        <!-- 面包屑 -->
        <div class="bread">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>联系我们</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 内容 -->
        <div class="main" v-loading="loading">
          <!-- 公司信息 -->
            <div class="module adress">
              <div class="moduleTitle">
                <img src="@/assets/image/contactUs/address.png" alt="">
                <div class="text">公司信息</div>
                <div class="greyLine"></div>
              </div>
              <el-row class="moduleContent" :gutter="0">
                <el-col class="left" :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                    <div class="adressItem">名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 称：南京沁恒微电子股份有限公司</div>
                    <div class="adressItem">地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 址：南京市宁双路18号·沁恒科技园</div>
                    <div class="adressItem">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 编：210012</div>
                    <div class="adressItem">总&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 机：025-84730668</div>
                    <div class="adressItem">传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 真：025-84730778</div>
                    <div class="adressItem">开户银行：南京银行雨花支行</div>
                    <div class="adressItem">账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 号：0125240000000770</div>
                </el-col>
                <el-col class="right" :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                    <baidu-map ref="BaiduMap" class="bm-view"  @ready="handler" ak="7RuYARTuolclAyiwnZHPx6lTC2sDRQHe"
                      :center="{lng:118.762202, lat:31.97814}" :zoom="15" :scroll-wheel-zoom="true" >
                      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
                    </baidu-map>
                </el-col>
              </el-row>
            </div>
            <!-- 销售 -->
            <div class="module adress">
              <div class="moduleTitle">
                <img src="@/assets/image/contactUs/sale.png" alt="">
                <div class="text">销售服务</div>
                <div class="greyLine"></div>
              </div>
              <el-row class="moduleContent" :gutter="0">
                <el-col class="left" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <div v-for="(item,index) in salePhones" :key="index" class="phoneType">
                    <div class="secondTitle">{{item.title}}</div>
                    <div class="phoneList">
                      <div class="phoneItem" v-for="(t,i) in item.tels" :key="i">{{t}}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="right" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <div class="phoneType">
                    <div class="secondTitle">销售邮箱</div>
                    <div class="saleEmail">
                      <img src="@/assets/image/contactUs/saleEmail.png" alt="">
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <!-- 技术 -->
            <div class="module adress">
              <div class="moduleTitle">
                <img src="@/assets/image/contactUs/tech.png" alt="">
                <div class="text">技术支持</div>
                <div class="greyLine"></div>
              </div>
              <el-row class="moduleContent" :gutter="0">
                <el-col class="left" :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-for="(item,index) in techPhones" :key="index" >
                  <div class="phoneType">
                    <div class="secondTitle">{{item.title}}</div>
                    <div class="phoneList">
                      <div class="phoneItem" v-for="(t,i) in item.tels" :key="i">{{t}}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="left" :xs="24" :sm="24" :md="24" :lg="12" :xl="12" >
                  <div class="phoneType">
                    <div class="secondTitle">技术邮箱</div>
                    <div class="saleEmail">
                      <!-- tech@wch.cn -->
                      <img src="@/assets/image/contactUs/techEmail.png" alt="">
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
        </div>
        <!-- 底部 -->
        <footer-part ref="footerPart"></footer-part>
    </div>
</template>
<script>
import topBar from '../topbar.vue'
import footerPart from '@/pages/components/footer.vue'
import { contactUs,} from '@/services/contactUs.js'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {BmNavigation, BmMapType, } from 'vue-baidu-map'
export default {
  name: "contactUs",
  components:{
    topBar,
    footerPart,
    BaiduMap,
    BmNavigation,
    BmMapType,
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        loading:false,
        salePhones:[],
        techPhones:[],
        center: {lng: 118.762202, lat:31.97814},
        zoom: 15
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth <= 768) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('mounted')
    document.title = '联系我们 - 南京沁恒微电子股份有限公司'
    this.getContent()
  },
  activated(){
    document.title = '联系我们 - 南京沁恒微电子股份有限公司'
    this.getContent()
   
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    getContent(){
      this.salePhones = []
      this.techPhones = []
      this.loading = true;
      contactUs().then(res => {
        if(res.data.code===0 && res.data.data){
          this.salePhones = res.data.data['sale']
          this.techPhones = res.data.data['tech']
          console.log('this.salePhones--',this.salePhones)
        }else{
          this.$message.error(res.data.message);
        }
        this.loading = false;
      });
    },
    // 地图加载
    handler ({BMap, map}) {
      console.log(BMap, map)
      this.center.lng = 118.762202
      this.center.lat = 31.97814
      this.zoom = 18
    },
   
    
  }
};
</script>
<style scoped>
 .container{
    width: 100%;
    margin: 0 auto;
    margin-top: 98px;
    overflow: auto;
 }
 .bread{
    width: 70%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    font-size: 18px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }

 .main {
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
    /* box-shadow: 6px 6px 12px rgb(0 0 0 / 18%); */
    border-radius: 4px;
    background: #fff;
    padding: 30px;
 }
 .module{
    margin: 15px 0px;
    margin-bottom: 35px;
 }
 .moduleTitle{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
 }
 .moduleTitle .text{
    font-size: 24px;
    line-height: 30px;
    margin: 0px 12px;
    font-weight: 600;
 }
 .moduleTitle .greyLine{
    flex: 1;
    height: 1px;
    background: #ccc;
 }
 .adress .moduleContent{
    width: 100%;
 }
 .adress .moduleContent .left{
    text-align: left;
 }
 .adressItem{
    font-size: 17px;
    line-height: 40px;
 }
/* 地图 */
.bm-view {
  width: 100%;
  height: 280px;
  border: 1px solid #ccc;
}
 .secondTitle{
    font-size: 18px;
    color: #1ea9fb;
    margin-bottom: 10px;
 }
 .phoneType{
    margin: 15px 0px;
    text-align: left;
 }
 .phoneList{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
 }
 .phoneItem{
    width: 37%;
    font-size: 17px;
    line-height: 30px;
    color: #333;
 }
 @media (max-width: 1199px) {
  .phoneItem{
    width: 50%;
 }
}
</style>