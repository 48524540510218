<template>
    <div class="container">
        <top-bar ref="topBar" ></top-bar>
        <!-- banner -->
        <div class="banner">
            <el-carousel :interval="5000" arrow="always" :height="autoHeight">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                  <img :style="{cursor:item.link?'pointer':'initial'}"  style="width:100%;height:auto;" @click="clickBanner(item.link)" :src="item.imgUrl" alt="">
                </el-carousel-item>
            </el-carousel>

            
        </div>
        <!-- 面包屑 -->
        <div class="bread">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>应用方案</el-breadcrumb-item>
            <!-- <el-breadcrumb-item>{{activeMenu.asideName}}</el-breadcrumb-item> -->
          </el-breadcrumb>
        </div>
        <!-- 内容 -->
        <div class="main" v-loading="loading">
            <div class="title">应用方案</div>
            <el-row :gutter="0" style="width:100%">
              <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" v-for="(item,index) in content" :key="index" class="applicationItem">
                <div class="applicationItem_content">
                    <div class="header">
                        <img :src="'https://www.wch.cn/assets/site/img/'+item.img" alt="">
                        <div class="headerText">{{item.name}}</div>
                    </div>
                    <div class="list">
                        <div v-for="(t,i) in item.articlesVOList" :key="i" class="listItem">
                            <span class="blueCircle"></span>
                            <span class="shortTitle" @click="clickShortTitle(t)">{{t.title}}</span>
                        </div>
                    </div>
                </div>
              </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <footer-part ref="footerPart"></footer-part>
    </div>
</template>
<script>
import topBar from '../topbar.vue'
import footerPart from '@/pages/components/footer.vue'
import { application } from '@/services/application.js'
export default {
  name: "application",
  components:{
    topBar,
    footerPart,
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        loading:false,
        autoHeight:'',
        bannerList:[
            {imgUrl:require('@/assets/image/application/banner1.png'), link:'https://www.wch.cn/application/660.html'},
            {imgUrl:require('@/assets/image/application/banner2.png'), link:'https://www.wch.cn/application/725.html'},
            {imgUrl:require('@/assets/image/application/banner3.jpeg'), link:'https://www.wch.cn/application.html'},
            {imgUrl:require('@/assets/image/application/banner4.jpeg'), link:'https://www.wch.cn/application/506.html'},
        ],
        content:[],
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth <= 768) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('mounted')
    if (this.windowWidth <= 768) {
      this.isMoPhone = true
    }else{
      this.isMoPhone = false
    }
    this.getApplication()
    this.getAutoHeight()
    document.title = '应用方案 - 南京沁恒微电子股份有限公司'
  },
  activated(){
    console.log('activated')
    this.getApplication()
    this.getAutoHeight()
    document.title = '应用方案 - 南京沁恒微电子股份有限公司'
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
      this.getAutoHeight()
    },
    getAutoHeight(){
        let _w = document.documentElement.clientWidth || document.body.clientWidth; 
        this.autoHeight = _w * 300 / 1920 + 'px'; //banner图在1920像素设计稿下是300px高度
    },
    //点击banner
    clickBanner(link){
        if(link){
            window.open(link)
        }
    },
    //获取应用方案
    getApplication(){
        this.content = []
        this.loading = true;
        application().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
                console.log('content---',this.content)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //点击方案
    clickShortTitle(t){
        console.log('ttt',t)
        this.$router.push({ name: 'ApplicationDetail', params: {applicationId:t.id+'.html'}})
    },
   
   
    
  }
};
</script>
<style scoped lang="less">
 .container{
    width: 100%;
    margin: 0 auto;
    margin-top: 98px;
    overflow: auto;
 }
 .bread{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    font-size: 18px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }
 .banner{
    width: 100%;
    // .el-carousel__container{
    //    height:300px
    // }
    // .bannerImg{
    //     width:100%; 
    //     height:300px
    // }
 }
 .main {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 6px 6px 12px #0000001a;
    background: #fff;
    border-radius: 4px;
    .title{
        font-size: 20px;
        color: #484848;
        font-weight: 700;
        margin-top: 20px;
        text-align: left;
        padding-left: 28px;
        margin-bottom: 15px;
    }
    .applicationItem{
        padding: 10px;
        background: #fff;
        .applicationItem_content{
            background-color: #f7f7f7;
            min-height: 520px;
            height: 100%;
            padding: 15px;
            border-color: transparent;
            .header{
                display: flex;
                margin-bottom: 10px;
                img{
                    width:50px;
                    height: 50px;
                }
                .headerText{
                    margin-left: 10px;
                    flex: 1;
                    border-bottom: 2px solid #0275d1;
                    text-align: left;
                    font-size: 18px;
                    line-height: 48px;
                }
            }
            .list{
                text-align: left;
                .listItem{
                    // display: flex; 
                    // align-items: center; 
                    margin: 14px 0px;
                    .blueCircle{
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        margin-right: 10px;
                        margin-bottom: 3px;
                        background: #0275d1;
                    }
                    .shortTitle{
                        font-size: 14px;
                        color: #333;
                        cursor: pointer;
                        &:hover{
                        color: #0275d1;
                        }
                    }
                }
            }
        }
    }
 }

@media screen and (max-width: 1400px) {
//    .banner /deep/.el-carousel__container{
//        height:250px
//     }
//     .banner .bannerImg{
//         height:250px
//     }
    .main .applicationItem .applicationItem_content .list .listItem .shortTitle{
        font-size: 12px;
    }
    .main .applicationItem .applicationItem_content .list .listItem{
        margin: 10px 0px;
    }
}
@media screen and (max-width: 1199px) {
    .main .applicationItem .applicationItem_content .list .listItem .shortTitle{
        font-size: 14px;
    }
    .main .applicationItem .applicationItem_content .list .listItem{
        margin: 14px 0px;
    }
    .main .applicationItem .applicationItem_content{
        min-height: auto;
    }
}
@media screen and (max-width: 1100px) {
    // .banner /deep/.el-carousel__container{
    //    height:220px
    // }
    // .banner .bannerImg{
    //     height:220px
    // }
}
@media screen and (max-width: 800px) {
    // .banner /deep/.el-carousel__container{
    //    height:180px
    // }
    // .banner .bannerImg{
    //     height:180px
    // }
}
@media screen and (max-width: 500px) {
    // .banner /deep/.el-carousel__container{
    //    height:140px
    // }
    // .banner .bannerImg{
    //     height:140px
    // }
}
</style>