<template>
  <div id="app">
   
    <router-view />
  </div>
</template>
<script>
import topBar from './pages/topbar.vue'
export default {
  name: 'app',
  components:{
    topBar
  },
  data() {
    return {
    };
  },
  computed: {

  },
   watch: {
    $route(to, from) {// 对路由监听
      console.log('to----',to)
      if(to.path.indexOf('/download/')>-1 ){
        let p = (to.path).replace('/download/','/downloads/')
        this.$router.push({ path: p, query: to.query})
      }
      if(to.path.indexOf('/product')>-1 ){
        let p = (to.path).replace('/product/','/products/')
        this.$router.push({ path: p, query: to.query})
      }
    },
  },
  mounted() {
    
  },
  created(){
  },
  activated(){
    
    
  },
  methods:{
    
    
  }
};
</script>

<style lang="less">
body {
  background-color: #f5f7fb;
  font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}

#app {
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
