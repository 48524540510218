<template>
    <div class="container">
        <top-bar ref="topBar" ></top-bar>
        <!-- banner -->
        <div class="banner">
            <img style="width:100%" src="@/assets/image/aboutUs/banner.png" alt="">
        </div>
        <!-- 面包屑 -->
        <div class="bread">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>关于我们</el-breadcrumb-item>
            <el-breadcrumb-item>{{activeMenu.asideName}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="activeMenu.key=='2'&&newItem.title">{{newItem.title}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 内容 -->
        <div class="main" >
          <el-row :gutter="0" style="width:100%">
            <el-col :xs="24" :sm="7" :md="6" :lg="5" :xl="4" style="padding:0px;">
              <side-bar ref="sideBar" :style="{width:isMoPhone?'100%':'93%', marginBottom:isMoPhone?'15px':'0px'}" v-loading="loading" 
                :asideList="asideList" :activeMenu="activeMenu" @currentAside = currentAside>
              </side-bar>
            </el-col>
            <el-col :xs="24" :sm="17" :md="18" :lg="19" :xl="20" style="padding:30px;background:#fff;">
              <keep-alive >
                  <router-view :key="viewKey" @currentNew="currentNew" />
              </keep-alive>
            </el-col>
          </el-row>
        </div>
        <!-- 底部 -->
        <footer-part ref="footerPart"></footer-part>
    </div>
</template>
<script>
import topBar from '../topbar.vue'
import footerPart from '@/pages/components/footer.vue'
import sideBar from '@/pages/components/sidebar'
export default {
  name: "aboutUs",
  components:{
    topBar,
    footerPart,
    sideBar,
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        activeMenu:{},
        asideList:[
          {
            asideName:'关于我们',
            childList:[
              {key:'1', asideName:'公司简介', path:'/about_us.html'},
              {key:'2', asideName:'新闻动态', path:'/news.html'},
              {key:'3', asideName:'荣誉与认证', path:'/certification.html'},
              {key:'4', asideName:'法律声明', path:'/statement.html'},
            ]
          }
        ],
        loading:false,
        content:'',
        newItem:{},
        
    };
  },
  computed: {
    viewKey() {
       return this.$route.path
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth <= 768) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('mounted')
    if (this.windowWidth <= 768) {
      this.isMoPhone = true
    }else{
      this.isMoPhone = false
    }

    if(this.$route.name=='AboutUs'){
      this.activeMenu = {key:'1', asideName:'公司简介', path:'/about_us.html'}
      this.$nextTick(()=>{
        this.currentAside(this.activeMenu)
      })
    }else if(this.$route.name=='NewItem'){
      this.activeMenu = {key:'2', asideName:'新闻动态', path:'/news.html'}
      document.title = this.activeMenu.asideName+' - 南京沁恒微电子股份有限公司'
      this.newItem = {}
    }else{
      for(let item of this.asideList[0].childList){
        if(item.path == this.$route.path){
          this.activeMenu = item
          break
        }
      }
      this.$nextTick(()=>{
        this.currentAside(this.activeMenu)
      })
    }
  },
  activated(){
    console.log('activated')
    if(this.$route.name=='AboutUs'){
      this.activeMenu = {key:'1', asideName:'公司简介', path:'/about_us.html'}
      this.$nextTick(()=>{
        this.currentAside(this.activeMenu)
      })
    }else if(this.$route.name=='NewItem'){
      this.activeMenu = {key:'2', asideName:'新闻动态', path:'/news.html'}
      document.title = this.activeMenu.asideName+' - 南京沁恒微电子股份有限公司'
      this.newItem = {}
    }else{
      for(let item of this.asideList[0].childList){
        if(item.path == this.$route.path){
          this.activeMenu = item
          break
        }
      }
      this.$nextTick(()=>{
        this.currentAside(this.activeMenu)
      })
    }
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    //点击侧边栏
    currentAside(item){
      console.log('点击的menu',item)
      console.log('this.$route.name',this.$route.name)
      this.newItem = {}
      this.activeMenu = item
      document.title = this.activeMenu.asideName+' - 南京沁恒微电子股份有限公司'
      this.$router.push({ path: item.path,})
    },
    //当前新闻详情
    currentNew(val){
        console.log('val',val)
        this.newItem = val
        document.title = this.newItem.title+' - 南京沁恒微电子股份有限公司'
        // this.$router.push({ path: '/news/'+val.id+'html',})

    },
   
    
  }
};
</script>
<style scoped>
 .container{
    width: 100%;
    margin: 0 auto;
    margin-top: 98px;
    overflow: auto;
 }
 .bread{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    font-size: 18px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }

 .main {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    /* box-shadow: 6px 6px 12px rgb(0 0 0 / 18%); */
    border-radius: 4px;
 }
 
/* .aside{
  text-align: left;
  color: #000;
  background: #fff;
  padding: 14px;
  padding-top: 20px;
  padding-bottom: 5px;
  box-sizing: border-box;
  box-shadow: -2px 1px 10px 1px rgba(159,159,159,.2);
}
.aside .asideMenuTitle{
  height: 40px;
  line-height: 40px;
  background: #e7e6e6;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 10px
}
.aside .asideItem{
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 5px;
  padding-left: 10px
}
.asideItem:hover{
  background: #143657;
  color: #fff;
}
.activeItemClass{
  background: #143657;
  color: #fff;
  height: 35px;
  line-height: 35px;
  font-size: 17px;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 10px;
  font-weight: 600;
} */


@media screen and (max-width: 370px) {
    
   
}
</style>