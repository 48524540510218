<template>
  <div >
    <top-bar ref="topBar" @topbarCategoryId ="topbarCategoryId"></top-bar>
    <el-container class="container" >
        <el-row :gutter="0" style="width:100%">
          <el-col :xs="0" :sm="7" :md="6" :lg="5" :xl="4" style="padding:0px;">
               <!-- update---20230615 -->
              <div class="aside" v-loading="loading" >
                <div class="asideMenu" v-for="(item,index) in asideList" :key="index" >
                  <!-- 一级菜单 -->
                  <div v-if="item.key" @click="clickAside(item)" :class="item.id==activeMenuId ? 'activeMenuClass' : 'asideMenuTitle'">{{item.name}}</div> 
                  <div v-else  class="asideMenuTitle_" id="unPointer" >{{item.name}}</div> 
                  <!-- 二级菜单 -->
                  <div @click="clickAside(t)"  :class="t.id==activeMenuId&&!t.link ? 'activeItemClass' : 'asideItem'"  v-for="(t,i) in item.childCategoriesVOList" :key="i">
                    <!-- 存在三级菜单 -->
                    <div class="thridMenuContainer" v-if="t.childCategoriesVOList&&t.childCategoriesVOList.length>=1">
                      <el-tooltip 
                        class="toolTip"
                        popper-class="tipPopper_class"
                        effect="light"  placement="right-start" :visible-arrow="false">
                        <div slot="content" style="width:180px;">
                          <div class="asideChildItem"  @click="clickAsideChild(childT)" v-for="(childT, childI) in t.childCategoriesVOList" :key="childI">{{childT.name}}</div>
                        </div>
                        <div>{{t.name}}</div>
                      </el-tooltip>
                    </div>
                    <!-- 不存在三级菜单 -->
                    <span v-else>
                      {{t.name}}
                    </span>
                  </div>
                </div>
              </div>
              <!-- 专题页 -->
              <div class="aside specialAside">
                <div class="asideMenu" v-for="(item,index) in specialObj" :key="index" >
                  <!-- 一级菜单 -->
                  <div v-if="item.key" @click="clickAside(item)" :class="item.id==activeMenuId ? 'activeMenuClass' : 'asideMenuTitle'">{{item.name}}</div> 
                  <div v-else  class="asideMenuTitle_" id="unPointer" >{{item.name}}</div> 
                  <!-- 二级菜单 -->
                  <div @click="clickAside(t)"  :class="t.id==activeMenuId&&!t.link ? 'activeItemClass' : 'asideItem'"  v-for="(t,i) in item.childCategoriesVOList" :key="i">
                     {{t.name}}
                  </div>
                </div>
              </div>
          </el-col>
          <el-col :xs="24" :sm="17" :md="18" :lg="19" :xl="20" style="padding:0px;">
            <div class="main" :style="{background:$route.name=='HotProducts'?'#f5f7fb':'#fff',
             boxShadow:$route.name=='HotProducts'?'none':'-2px 1px 10px 1px rgba(159,159,159,.2)',padding:$route.name=='HotProducts'?'20px 0px':'20px'}">
              <keep-alive >
                <router-view :key="viewKey" @giveCategoryId="giveCategoryId" />
              </keep-alive>
            </div>
          </el-col>
      </el-row>
    </el-container>
  </div>
</template>

<script>
import {getAside } from '@/services/product.js'
import topBar from './topbar.vue'
export default {
  name: "Home",
  components:{
    topBar
  },
  data() {
    return {
        specialObj:[{
          key:'',
          name:'特色专题',
          childCategoriesVOList:[//直接跳已有链接
            {key:'/specialFeatures/1',id:'specialFeatures1', name:'RISC-V产品总览', link:'https://special.wch.cn/zh_cn/RISCV_MCU_Index/', childCategoriesVOList:[]},
            {key:'/specialFeatures/2',id:'specialFeatures2', name:'USB产品索引', link:'https://special.wch.cn/zh_cn/USBChips/#/', childCategoriesVOList:[]},
            {key:'/specialFeatures/3',id:'specialFeatures3', name:'接口转换专家', link:'https://special.wch.cn/zh_cn/interface_conversion_expert/', childCategoriesVOList:[]},
            {key:'/specialFeatures/4',id:'specialFeatures4', name:'Type-C应用产品索引', link:'https://special.wch.cn/zh_cn/type-c_application_index/', childCategoriesVOList:[]},
            {key:'/specialFeatures/9',id:'specialFeatures9', name:'无线充电产品索引', link:'https://special.wch.cn/zh_cn/wireless_charging_index/', childCategoriesVOList:[]},
            {key:'/specialFeatures/5',id:'specialFeatures5', name:'蓝牙芯片与模块选型', link:'https://special.wch.cn/zh_cn/BLEChip_Module_Index/', childCategoriesVOList:[]},
            {key:'/specialFeatures/8',id:'specialFeatures8', name:'电机控制产品索引', link:'https://special.wch.cn/zh_cn/motor_control_index/', childCategoriesVOList:[]},
            {key:'/specialFeatures/6',id:'specialFeatures6', name:'MCU选型', link:'https://special.wch.cn/zh_cn/mcu/', childCategoriesVOList:[]},
            {key:'/specialFeatures/7',id:'specialFeatures7', name:'USB转串口芯片选型', link:'https://special.wch.cn/zh_cn/produce/', childCategoriesVOList:[]},
          ],
        }],
        asideList:[],
        activeMenuId:'',
        categoryId:'',
        loading:false,
        url:{
          asideUrl:'/api/official/website/categories/sidebar'
        },
        
    };
  },
  computed: {
    viewKey() {
      return this.$route.path
    }
  },
  watch:{
  },
  mounted() {
    this.getAsideMenuList()
  },
  activated(){
    console.log('$route.path',viewKey)
  },
  methods:{
    //获取地址栏参数（当前激活侧边栏）
    giveCategoryId(categoryId){
      this.activeMenuId = categoryId
    },
    //点击侧边栏
    clickAside(item){
      console.log('点击的menu',item)
      this.activeMenuId = item.id
      if(item.link){//跳现有链接
        window.open(item.link)
      }else{//跳路由
        if(item.key){
          this.$router.push({ path: item.key, query: {categoryId:item.id}})
        }
      }
    },
    //点击三级侧边栏
    clickAsideChild(item){
      this.activeMenuId = item.pid
      if (item.pid == 69) {
        this.$router.push({ path:'/products/productsCenter/otherChip', query: {categoryId:item.pid, tName:item.name.replace(/\&/g,'_')}})
      } else {
        if(this.$route.query.tName!=item.name.replace(/\&/g,'_')){
          this.$router.push({ path:'/products/productsCenter/mcuInterface', query: {categoryId:item.pid, tName:item.name.replace(/\&/g,'_')}})
        }else{
        }
      }
    },
    //获取侧边栏列表
    getAsideMenuList(){
      getAside().then(res => {
        console.log('home的res----',res)
        this.loading = true;
        if(res.data.code===0 && res.data.data.length){
          let arr = res.data.data
          for(let item of arr){
            item.key='/products/productsCenter'
            item.childCategoriesVOList.forEach((t)=>{
              
              if(t.id=='69'){//其他芯片 => 加载对应模板
                t.key = '/products/productsCenter/otherChip'
              }else if(t.id=='77'){//关于青稞内核
                t.key = '/products/QingKe.html'
              }
              else{
                t.key = '/products/productsCenter/mcuInterface'
              }
            })
          }
          this.$nextTick(()=>{
            this.asideList = arr
          })
        }else{
          this.$message.error(res.data.message);
        }
        this.loading = false;
      });

    },

    topbarCategoryId(i) {
      this.activeMenuId = i
    } ,     
     
     
     
      
  }
};
</script>

<style lang="less">
 .tipPopper_class{  
	background: white !important;  
	box-shadow: 1px 1px 10px 3px #D3D3D6;
  border: none;
}
.tipPopper_class.el-tooltip__popper{
  padding:5px !important;
  border-radius: 4px !important;
  border-color:#e6e6e6 !important;
  box-shadow: 0 2px 8px 2px #ccc !important;
}
</style>

<style scoped lang="less">
.floating-iframe {
  overflow: hidden;
  position: absolute;
  top: 41.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* 设置iframe的层级，确保其在顶层 */
  border: 1px solid #ccc;
  pointer-events: none;
}

.container {
  width: 96%;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  margin-top: 98px;
  overflow: auto;
}
.specialAside{
  margin-top: 15px;
}
.aside{
  width: 93%;
  text-align: left;
  color: #000;
  background: #fff;
  padding: 14px;
  padding-top: 20px;
  padding-bottom: 5px;
  box-sizing: border-box;
  box-shadow: -2px 1px 10px 1px rgba(159,159,159,.2);
}
.aside .lan{
  display:flex;
  justify-content: space-between;
}
.aside .lan .arrow{
  color:#ec7d31;
}

.aside .asideMenu .asideMenuTitle{
  height: 40px;
  line-height: 40px;
  background: #e7e6e6;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 10px
}
.aside .asideMenu .asideMenuTitle_{
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 10px;
  background:#e1edf8;
}
.asideMenuTitle:hover{
 background: #143657 !important;
 color: #fff;
 font-weight: 600;
}
.activeMenuClass{
  background: #143657;
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 10px;
  font-weight: 600;
  // box-shadow: 0 1px 4px #d9d9d9, 0 0 20px #d9d9d9;
}
.aside .asideItem{
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 5px;
  padding-left: 10px
}
.asideItem:hover{
  background: #143657;
  color: #fff;
  // font-weight: 600;
}
.activeItemClass{
  background: #143657;
  color: #fff;
  height: 35px;
  line-height: 35px;
  font-size: 17px;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 10px;
  font-weight: 600;
  // box-shadow: 0 1px 4px #d9d9d9, 0 0 20px #d9d9d9;
}
#unPointer:hover{
  color:#333;
  text-decoration: none;
}
.asideChildItem{
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 5px;
  padding: 3px 5px;
  border-radius: 3px;
  // border-bottom:1px solid #e5e5e5;
}
.asideChildItem:hover{
  background: #143657;
  font-weight: 600;

  color: #fff;
}
.thridMenuContainer{
  position: relative;
}
.thridMenuContainer .childBox{
  position: absolute;
  border: 1px solid #ccc;
}


.main{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  // background: #fff;
  // box-shadow: -2px 1px 10px 1px rgba(159,159,159,.2);
}

.el-col {
    border-radius: 4px;
  }
 
  .bg-purple {
    background: #ccc;
  }
  .bg-purple-light {
    background: blue;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }


</style>
