import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "@/pages/homePage.vue";
import Home from "@/pages/Home.vue";
import AboutUs from "@/pages/aboutUs";
import ContactUs from "@/pages/contactUs"
import JoinUs from "@/pages/joinUs";
import Application from "@/pages/application";
import Services from "@/pages/services";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/products",
    name: "Home",
    component: Home,
    redirect: '/products/productsCenter',
    children: [
      {
        path: 'productsCenter',
        name:'Products',
        component: () => import('@/pages/products'),
      },
      {
        path: 'productsCenter/mcuInterface',
        name:'McuInterface',
        component: () => import('@/pages/products/modules/mcuInterface'),
      },
      {
        path: 'productsCenter/otherChip',
        name:'OtherChip',
        component: () => import('@/pages/products/modules/otherChip'),
      },
      {
        path: 'productsCenter/hotProducts',
        name:'HotProducts',
        component: () => import('@/pages/products/modules/hotProducts'),
      },
      // {
      //   path: ':productName',
      //   name:'QingKe',
      //   component: () => import('@/pages/products/modules/QingKe'),
      // },
      {
        path: 'QingKe.html',
        name:'QingKe',
        component: () => import('@/pages/products/modules/QingKe'),
      },
      {
        path: ':productName',
        name:'ChipDetail',
        component: () => import('@/pages/products/modules/chipDetail'),
      },
    ]
  },
  //关于我们（多个路由地址对应同个组件）
  {
    path: '/',
    name:'AboutUs',
    component: AboutUs,
    children: [
      {
        path: 'about_us.html',
        name:'AboutUs',
        component: () => import('@/pages/aboutUs/modules/company'),
      },
      {
        path: 'news.html',
        name:'News',
        component: () => import('@/pages/aboutUs/modules/company'),
      },
      {
        path: 'news/:id.html',
        name:'NewItem',
        component: () => import('@/pages/aboutUs/modules/company'),
      },
      {
        path: 'certification.html',
        name:'Certification',
        component: () => import('@/pages/aboutUs/modules/company'),
      },
      {
        path: 'statement.html',
        name:'Statement',
        component: () => import('@/pages/aboutUs/modules/company'),
      },
    ],
  },
  //联系我们
  {
    path: '/contact_us.html',
    name:'ContactUs',
    component: ContactUs,
  },
  //加入我们
  {
    path: '/',
    name:'JoinUs',
    component: JoinUs,
    children: [
      {
        path: 'talentconcept.html',
        name:'Talentconcept',
        component: () => import('@/pages/joinUs/modules/talentconcept'),
      },
      {
        path: 'recruitment/campus.html',
        name:'Campus',
        component: () => import('@/pages/joinUs/modules/talentconcept'),
      },
      {
        path: 'recruitment/joinus.html',
        name:'Joinus',
        component: () => import('@/pages/joinUs/modules/talentconcept'),
      },
    ],
  },
  //应用方案
  {
    path: '/application.html',
    name:'Application',
    component: Application,
  },
  {
    path: '/application/:applicationId',
    name:'ApplicationDetail',
    component: () => import('@/pages/application/modules/applicationDetail'),
  },
  //服务支持
  {
    path: '/services',
    name:'Services',
    component: Services,
    // redirect: '/services/sale_support.html',
    children: [
      {//样品索取
        path: 'request_sample.html',
        name:'RequestSample',
        component: () => import('@/pages/services/modules/request_sample'),
      },
      {//技术支持
        path: 'technical_support.html',
        name:'TechnicalSupport',
        component: () => import('@/pages/services/modules/sale_support'),
      },
      {//销售服务
        path: 'sale_support.html',
        name:'SaleSupport',
        component: () => import('@/pages/services/modules/sale_support'),
      },
      {//购买流程
        path: 'buy_process.html',
        name:'BuyProcess',
        component: () => import('@/pages/services/modules/buy_process'),
      }
    ],
  },
  {//资料下载
    path: '/downloads',
    name:'DownloadsCategory',
    component: () => import('@/pages/services/modules/downloadsCategory'),
    children: [
      {//兼容英文站
        path: 'file/:id.html',
        name:'ProductManual',
        component: () => import('@/pages/DownloadFile'),
      },
      {//产品手册
        path: 'category/27.html',
        name:'ProductManual',
        component: () => import('@/pages/services/modules/downloadModules/productManual'),
      },
      {//开发资源
        path: 'category/28.html',
        name:'DevelopResource',
        component: () => import('@/pages/services/modules/downloadModules/productManual'),
      },
      {//工具软件
        path: 'category/30.html',
        name:'ToolSoft',
        component: () => import('@/pages/services/modules/downloadModules/productManual'),
      },
      {//其他
        path: 'category/29.html',
        name:'OtherResource',
        component: () => import('@/pages/services/modules/downloadModules/productManual'),
      },
      {//驱动程序
        path: 'category/67.html',
        name:'Driver',
        component: () => import('@/pages/services/modules/downloadModules/driver'),
      },
      {//具体某资料
        path: ':name',
        name:'ResourceDetail',
        component: () => import('@/pages/services/modules/downloadModules/resourceDetail'),
      },
    ],
  },
  {//视频资料
    path: '/videos.html',
    name:'Videos',
    component: () => import('@/pages/services/modules/downloadModules/videos'),
  },
  {//视频资料-单个视频
    path: '/videos/:name',
    name:'singleVideo',
    component: () => import('@/pages/services/modules/downloadModules/videos'),
  },
  // {//工程变更通知
  //   path: '/engineering_change_notification.html',
  //   name:'EngineeringChangeNotice',
  //   component: () => import('@/pages/services/modules/engineeringChangeNotice'),
  // },
  // {//变更详情
  //   path: '/engineering_change_notification/:changeId',
  //   name:'EngineChangeDetail',
  //   component: () => import('@/pages/services/modules/engineeringChangeNotice'),
  // },
  {//搜索页
    path: '/search.html',
    name:'Search',
    component: () => import('@/pages/search'),
  },
  {
    path: '/qq',
    name:'QQ',
    component: () => import('@/pages/qq/qq'),
  },
  {
    path: '*',
    name:'404',
    component: () => import('@/pages/404'),
  },
];

//原
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  routes,
  // base: '/',
  mode:'history',
});

export default router;
