<template>
    <div class="sidebar" v-loading="loading">
        <!-- 侧边栏组件 -->
        <div class="aside" v-for="(item, index) in asideList" :key="index">
            <div class="asideMenuTitle" v-if="item.asideName">{{item.asideName}}</div>
            <div v-for="(t,i) in item.childList" :key="i" @click="clickAside(t)" :class="t.key==activeMenu.key ? 'activeItemClass' : 'asideItem'">
                {{t.asideName}} <span v-if="t.num||t.num===0">（{{t.num?t.num:0}}）</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "sidebar",
  components:{
  },
  props:['loading','asideList','activeMenu'],
  data() {
    return {
       
    };
  },
  computed: {
  },
  created() {
  },
  destroyed() {
  },
  watch: {
  },
  mounted() {
  },
  activated(){
  },
  methods:{
    //点击侧边栏
    clickAside(item){
       this.$emit('currentAside',item)
    },
   
  }
};
</script>
<style scoped lang="less">
  .sidebar{
    text-align: left;
    color: #000;
    background: #fff;
    padding: 14px;
    padding-top: 20px;
    padding-bottom: 15px;
    box-sizing: border-box;
    box-shadow: -2px 1px 10px 1px rgba(159,159,159,.2);

    .asideMenuTitle{
        height: 40px;
        line-height: 40px;
        background: #e7e6e6;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 5px;
        padding-left: 10px
    }
    .asideItem{
        height: 35px;
        line-height: 35px;
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 5px;
        padding-left: 10px
    }
    .asideItem:hover{
        background: #143657;
        color: #fff;
    }
    .activeItemClass{
        background: #143657;
        color: #fff;
        height: 35px;
        line-height: 35px;
        font-size: 17px;
        cursor: pointer;
        margin-bottom: 5px;
        padding-left: 10px;
        font-weight: 600;
    }
  }
</style>