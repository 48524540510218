const footer = {
    state: {
      footerList: [],
    },
  
    mutations: {
      SET_FOOTERLIST: (state, footerList) => {
        state.footerList = footerList
      },
    },
  
    actions: {
     
    }
  }
  
  export default footer





